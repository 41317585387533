export * from "ce/reducers/entityReducers/canvasWidgetsStructureReducer";
import { createImmerReducer } from "utils/ReducerUtils";
import type { FlattenedWidgetProps } from "ce/reducers/entityReducers/canvasWidgetsStructureReducer";
import {
  initialState,
  handlers as CE_handlers,
  type CanvasWidgetsStructureReduxState,
} from "ce/reducers/entityReducers/canvasWidgetsStructureReducer";
import { denormalize } from "utils/canvasStructureHelpers";
import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import type { ReduxAction } from "actions/ReduxActionTypes";
import type { CreateUIModuleSuccessPayload } from "ee/actions/moduleActions";
const handlers = {
  ...CE_handlers,

  [ReduxActionTypes.CREATE_UI_MODULE_SUCCESS]: (
    draftState: CanvasWidgetsStructureReduxState,
    action: ReduxAction<CreateUIModuleSuccessPayload<FlattenedWidgetProps>>,
  ) => {
    return denormalize("0", action.payload.widgets);
  },
};

const canvasWidgetsStructureReducer = createImmerReducer(
  initialState,
  handlers,
);

export default canvasWidgetsStructureReducer;
