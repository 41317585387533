export * from "ce/selectors/packageSelectors";
import type { AppState } from "ee/reducers";

import { createSelector } from "reselect";
import type { Module } from "ee/constants/ModuleConstants";
import type { Package } from "ee/constants/PackageConstants";
import {
  ENTITY_EXPLORER_RENDER_ORDER,
  MODULE_TYPE,
  PACKAGE_PULL_STATUS,
} from "ee/constants/ModuleConstants";

export const getIsFetchingPackages = (state: AppState) =>
  state.ui.workspaces.loadingStates.isFetchingPackagesList;

export const getIsCreatingPackage = (state: AppState, workspaceId: string) =>
  state.ui.workspaces.loadingStates.packageCreationRequestMap[workspaceId];

export const getPackagesList = (state: AppState) =>
  state.ui.workspaces.packagesList;

export const getCurrentPackageId = (state: AppState) =>
  state.ui.editor?.currentPackageId;

export const getCurrentBasePackageId = (state: AppState) =>
  state.ui.editor?.currentBasePackageId;

export const getPackages = (state: AppState) => state.entities.packages;

export const getCurrentPackage = createSelector(
  getCurrentPackageId,
  getPackages,
  (currentPackageId, packages) =>
    currentPackageId ? packages[currentPackageId] : null,
);

export const getIsPackageEditorInitialized = (state: AppState) =>
  state.ui.editor.isPackageEditorInitialized;

export const getIsSavingPackageName = (state: AppState) =>
  state.ui.workspaces.isSavingPkgName;

export const getisErrorSavingPackageName = (state: AppState) =>
  state.ui.workspaces.isErrorSavingPkgName;

export const getIsPackagePublishing = (state: AppState) =>
  state.ui.editor.isPackagePublishing;

export const getFirstModule = (state: AppState) => {
  const modules = Object.values(state.entities.modules);
  const firstModuleOfEachType: Record<MODULE_TYPE, Module | undefined> = {
    [MODULE_TYPE.UI]: undefined,
    [MODULE_TYPE.QUERY]: undefined,
    [MODULE_TYPE.JS]: undefined,
  };

  modules.forEach((module) => {
    if (!firstModuleOfEachType[module.type]) {
      firstModuleOfEachType[module.type] = module;
    }
  });

  return ENTITY_EXPLORER_RENDER_ORDER.reduce(
    (acc: Module | undefined, next) => {
      acc = acc || firstModuleOfEachType[next];

      return acc;
    },
    undefined,
  );
};

export const getModulesMetadata = (state: AppState) =>
  state.ui.explorer.modulesMetadata;

export const getModulesMetadataById = (state: AppState, id: string) =>
  state.ui.explorer.modulesMetadata[id];

export const getPackageById = (
  state: AppState,
  id: string,
): Package | undefined => state.entities.packages[id];

export const getPackageByBaseId = createSelector(
  getPackages,
  (_: AppState, basePackageId: string) => basePackageId,
  (packages, basePackageId) => {
    return Object.values(packages).find((pkg) => pkg.baseId === basePackageId);
  },
);

export const getPackagePullStatus = (state: AppState) =>
  state.ui.editor.packagePullStatus;

export const getIsPackageUpgrading = (state: AppState) =>
  state.ui.editor.packagePullStatus === PACKAGE_PULL_STATUS.UPGRADING;
