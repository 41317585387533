export * from "ce/reducers/entityReducers/canvasWidgetsReducer";
import type { ReduxAction } from "actions/ReduxActionTypes";
import type {
  CanvasWidgetsReduxState,
  FlattenedWidgetProps,
} from "ce/reducers/entityReducers/canvasWidgetsReducer";
import {
  handlers as CE_handlers,
  initialState,
} from "ce/reducers/entityReducers/canvasWidgetsReducer";
import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import type { CreateUIModuleSuccessPayload } from "ee/actions/moduleActions";
import { createImmerReducer } from "utils/ReducerUtils";
import { getCanvasBottomRow } from "utils/WidgetSizeUtils";

const handlers = {
  ...CE_handlers,
  [ReduxActionTypes.CREATE_UI_MODULE_SUCCESS]: (
    draftState: CanvasWidgetsReduxState,
    action: ReduxAction<CreateUIModuleSuccessPayload<FlattenedWidgetProps>>,
  ) => {
    const { widgets } = action.payload;
    const newWidgets: CanvasWidgetsReduxState = {};

    for (const [widgetId, widgetProps] of Object.entries(widgets)) {
      if (widgetProps.type === "CANVAS_WIDGET") {
        const bottomRow = getCanvasBottomRow(widgetId, widgets);

        newWidgets[widgetId] = {
          ...widgetProps,
          bottomRow,
        };
      } else {
        newWidgets[widgetId] = widgetProps;
      }
    }

    return newWidgets;
  },
};

const canvasWidgetsReducer = createImmerReducer(initialState, handlers);

export default canvasWidgetsReducer;
