import { APP_SIDEBAR_WIDTH } from "constants/AppConstants";
import type { AnimatedGridUnit } from "components/AnimatedGridLayout";
import {
  API_EDITOR_BASE_PATH,
  JS_COLLECTION_EDITOR_PATH,
  QUERIES_EDITOR_BASE_PATH,
} from "constants/routes";
import { SAAS_EDITOR_PATH } from "ee/constants/routes/workflowRoutes";

export const Areas = {
  Sidebar: "Sidebar",
  Explorer: "Explorer",
  CodeEditor: "CodeEditor",
  BottomBar: "BottomBar",
} as const;

export type Area = keyof typeof Areas;

export const SIDEBAR_WIDTH = (APP_SIDEBAR_WIDTH + "px") as AnimatedGridUnit;

export const queryModuleSegmentRoutes = [
  API_EDITOR_BASE_PATH,
  `${SAAS_EDITOR_PATH}/api`,
  QUERIES_EDITOR_BASE_PATH,
];

export const jsSegmentRoutes = [JS_COLLECTION_EDITOR_PATH];
