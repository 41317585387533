export const RagConstants = {
  // Number of chunks which RAG should return.
  DEFAULT_SEARCH_NUMBER_OF_CHUNKS: 20,
  // Whether to use high accuracy or not.
  DEFAULT_SEARCH_HIGH_ACCURACY: true,
  DEFAULT_SEARCH_INCLUDE_RAW_FILE: true,
  DEFAULT_DOCUMENT_INCLUDE_RAW_FILE: true,
  DEFAULT_DOCUMENT_INCLUDE_PARSED_RAW_FILE: true,
  DEFAULT_DOCUMENT_PAGINATION_LIMIT: 100,
  MAX_DOCUMENT_PAGINATION_LIMIT: 250,
  CITATION_PATTERN: "<span data-citation-id=':citationId'></span>", // This is a regex pattern
  COLON: ":",
  COMMA: ",",
  INSTANCE_ID: "instanceId",
  TENANT_ID: "tenantId",
  WORKSPACE_ID: "workspaceId",
  DATASOURCE_ID: "datasourceId",
  WIDGET_ID: "widgetId",
  USERNAME: "username",
};
